let WeChatPay = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    this.VerificationSuccessPay = function(item, success, error) {
        var url = ServiceBaseAddress + '/api/WeChatPay/VerificationSuccessPay'
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            item,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
}

export { WeChatPay }
